<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SENIOR_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="seniorClassCd"
            label="구분"
            v-model="searchParam.seniorClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="선임명 목록"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" label="추가" :showLoading="true" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="저장"
            icon="save"
            @beforeAction="saveSenior"
            @btnCallback="saveCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'educationCourseNames'">
          <c-tag 
            :editable="editable"
            icon="person_outline"
            itemText="educationCourseName"
            itemValue="check"
            name="eduList" 
            v-model="props.row.eduList"
            @addTag="addEduMaster(props.row)"
            @removeTag="item => removeEduMaster(item, props.row)"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "senior-dismissal-master",
    data() {
    return {
      searchParam: {
        seniorClassCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [],
        data: [],
      },
      useFlagItems: [],
      row: null,
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.senior.list.url;
      this.saveUrl = transactionConfig.mdm.senior.save.url;
      this.deleteUrl = transactionConfig.mdm.senior.delete.url;
      // code setting
      // list setting
      this.getList();

      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('SENIOR_CLASS_CD').then(item1 => { // 선해임 구분
        this.$comm.getComboItems('SENIOR_REPORT_APPOINT_CD').then(item3 => { // 신고/선임 구분
          this.$comm.getComboItems('RELATED_LAWS_CD').then(item4 => { // 관련법규
            this.grid.columns = [
              {
                required: true,
                name: "seniorClassCd",
                field: "seniorClassCd",
                label: "구분",
                style: 'width:100px',
                align: "center",
                type: 'select',
                sortable: true,
                comboItems: item1,
              },
              {
                name: "relatedLaws",
                field: "relatedLaws",
                label: "관련법규",
                style: 'width:250px',
                align: 'center',
                itemText: 'codeName',
                itemValue: 'code',
                type: "multiSelect",
                sortable: true,
                comboItems: item4,
                isArray: false,
              },
              {
                required: true,
                name: "seniorName",
                field: "seniorName",
                label: "선임명",
                style: 'width:200px',
                align: "left",
                type: 'text',
                sortable: true,
              },
              {
                name: "educationCourseNames",
                field: "educationCourseNames",
                label: "교육과정",
                style: 'width:250px',
                align: 'center',
                type: "custom",
                sortable: true,
              },
              {
                name: "qualifiyRequire",
                field: "qualifiyRequire",
                label: "세부법규",
                align: "left",
                type: 'textarea',
                style: 'width:250px',
                sortable: true,
              },
              {
                name: "reportAppointCd",
                field: "reportAppointCd",
                label: "신고/선임",
                align: "center",
                style: 'width:100px',
                type: 'select',
                sortable: true,
                comboItems: item3,
              },
              {
                name: "useFlag",
                field: "useFlag",
                label: "사용여부",
                align: "center",
                style: 'width:80px',
                type: 'check',
                sortable: true,
                true: 'Y',
                false: 'N'
              },
            ]
          });
        });
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        seniorId: uid(),
        relatedLaws: '',
        qualifiyRequire: '',
        useFlag: 'Y',
        seniorName: '',
        seniorStandard: '',
        seniorClassCd: null,
        reportAppointCd: null,
        seniorFieldCd: null,
        regUserId: this.$store.getters.user.userId,
        eduList: [],
        editFlag: 'C',
      })
    },
    /* eslint-disable no-unused-vars */ 
    addEduMaster(row) {
      this.row = row;
      this.popupOptions.title = '교육과정 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCoursePop.vue'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (!this.row.eduList) this.row.eduList = [];
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, item => {
          if (this.$_.findIndex(this.row.eduList, { eduCourseId: item.eduCourseId }) === -1) {
            this.row.eduList.push({
              eduCourseId: item.eduCourseId,
              educationCourseName: `(${item.plantName}) ${item.educationCourseName}`,
              seniorId: this.row.seniorId,
            })
          }
          if (this.row.editFlag !== 'C') {
            this.row.chgUserId = this.$store.getters.user.userId
            this.row.editFlag = 'U'
          }
        })
      }
    },
    removeEduMaster(item, row) {
      row.eduList = this.$_.reject(row.eduList, {eduCourseId: item.eduCourseId})
      if (row.editFlag !== 'C') {
        row.chgUserId = this.$store.getters.user.userId
        row.editFlag = 'U'
      }
    },
    saveSenior() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  },
};
</script>
